/*----- Imports -----*/
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

/*----- Commons-----*/
import Montserrat from '../../commons/fonts/Montserrat'

/*----- Styles-----*/
const StyledNavButton = styled(NavLink)`
	${ Montserrat(12, 400, 'white') };
	display: flex;
	align-items: center;
	text-decoration: none;
	text-transform: capitalize;
	flex-direction: column;
	padding: 6px;
	min-width: 100px;
	justify-content: center;
	max-width: 200px;
	border: none;
	transition: all 0.3s ease-out;
	&:focus {
		outline: none;
	}
	&:hover {
		color: black;
	}
	
`

/*----- Component -----*/
const NavButton = ({ content, destination }) => {
	return(
		<StyledNavButton to={destination} className="min-nav-button">
	   { content }
  	</StyledNavButton>
	)
}
export default NavButton

/*----- PropTypes -----*/
NavButton.propTypes = {
  content:	 	PropTypes.string.isRequired,
  destination:	PropTypes.string.isRequired
}
