import Color      from '../../commons/Color'
import Montserrat from '../../commons/fonts/Montserrat'
import Device     from '../../commons/Devices'

function insuranceStyles() {
	return (
		`
			.insurance-icon-list__container {
				background-color: ${ Color.monochrome.softgrey};
			}
			.insurance-icon-list {
				display: grid;
				grid-template-columns: 33% 33% 33%;
				// padding-right: 24px;
				// grid-column-gap: 8px;
			}
			.insurance-icon-list__item {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 16px 8px;
				.insurance-icon-list__title {
					${ Montserrat(12, 600, Color.monochrome.black) };
					margin-top: 8px;
					text-align: center;
				}
			}
			@media ${ Device.lg } {
				.insurance-icon-list {
					grid-template-columns: repeat(auto-fill, minmax(100px, 1fr))
				}
			}
			@media ${ Device.xl } {
				.insurance-icon-list {
					grid-template-columns: repeat(auto-fill, minmax(120px, 1fr))
				}
			}
		`
	)
}
export default insuranceStyles