/*----- Core -----*/
import React 	from 'react'
import styled from 'styled-components'

/*----- Components -----*/
import IntivaLogo from '../../assets/logos/intiva_logo.svg'

/*----- Commons -----*/
import Montserrat from '../../commons/fonts/Montserrat'

/*----- Styles -----*/
const StyledFooter = styled.a`
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: start;
	width: 100%;
	padding: 16px 32px;
	margin-top: 16px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-direction: row;
	.intiva-footer__paragraph {
		${ Montserrat(12, 400, 'black') };
		margin: 0 16px 0 0;
	}
	.intiva-footer_logo {
		width: 50px;
		margin-bottom: 3px;
	}
`

/*----- Component -----*/
const IntivaFooter = () => {
	return(
		<StyledFooter className="intiva-footer" href="https://intivastudio.com/" target="_blank" rel="noopener noreferrer">
			<p className="intiva-footer__paragraph">
				Diseñado y desarrollado por
			</p>
			<div className="intiva-footer_logo">
				<img src={IntivaLogo} alt="Intiva logo"/>
			</div>
		</StyledFooter>
	)
}

export default IntivaFooter