function Padding() {
	return (
		`
			.p-1 {
				padding: 8px;
			}
			.p-2 {
				padding: 16px;
			}
			.p-3 {
				padding: 32px;
			}
			.p-4 {
				padding: 64px;
			}
			.p-x-1 {
				padding-right: 8px;
				padding-left: 8px;
			}
			.p-x-2 {
				padding-right: 16px;
				padding-left: 16px;
			}
			.p-x-3 {
				padding-right: 32px;
				padding-left: 32px;
			}
			.p-x-4 {
				padding-right: 64px;
				padding-left: 64px;
			}
			.p-y-1 {
				padding-top: 8px;
				padding-bottom: 8px;
			}
			.p-y-2 {
				padding-top: 16px;
				padding-bottom: 16px;
			}
			.p-y-3 {
				padding-top: 32px;
				padding-bottom: 32px;
			}
			.p-y-4 {
				padding-top: 64px;
				padding-bottom: 64px;
			}
			.p-l-1 {
				padding-left: 8px;
			}
			.p-l-2 {
				padding-left: 16px;
			}
			.p-l-3 {
				padding-left: 32px;
			}
			.p-l-4 {
				padding-left: 64px;
			}
			.p-r-1 {
				padding-right: 8px;
			}
			.p-r-2 {
				padding-right: 16px;
			}
			.p-r-3 {
				padding-right: 32px;
			}
			.p-r-4 {
				padding-right: 64px;
			}
			.p-t-1 {
				padding-top: 8px;
			}
			.p-t-2 {
				padding-top: 16px;
			}
			.p-t-3 {
				padding-top: 32px;
			}
			.p-t-4 {
				padding-top: 64px;
			}
			.p-b-1 {
				padding-bottom: 8px;
			}
			.p-b-2 {
				padding-bottom: 16px;
			}
			.p-b-3 {
				padding-bottom: 32px;
			}
			.p-b-4 {
				padding-bottom: 64px;
			}
		`
	)
}
export default Padding