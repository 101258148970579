/*----- Imports -----*/
import React 							from 'react'
import styled 						from 'styled-components'

/*----- Components -----*/
import MinNavButton from '../../components/buttons/MinNavButton'
import Device 			from '../../commons/Devices'
import Color			  from '../../commons/Color'

/*----- Styles -----*/
const StyledAsideMenu = styled.footer`
	background-color: ${ Color.main};
	display: flex;
	flex-direction: column;
	justify-content: start;
	width: 100%;
	padding: 16px 32px;
	.min-nav-button {
		align-items: start;
	}

	/*** Media queries ***/
	@media ${ Device.md } {
		flex-direction: row;
		justify-content: center;
		.min-nav-button {
			text-align: center;
		}
	}
`

/*----- Component -----*/
const AsideMenu = () => {
	return(
		<StyledAsideMenu className="aside-menu m-y-3">
			<MinNavButton 
				content		  = "home"
				destination = "/"
			/>
			<MinNavButton 
				content		  = "promo"
				destination = "/promo"
			/>
			<MinNavButton 
				content		  = "contacto"
				destination = "/contacto"
			/>
			<MinNavButton 
				content		  = "nosotros"
				destination = "/nosotros"
			/>
			<MinNavButton 
				content		  = "individuales"
				destination = "/individuales"
			/>
			<MinNavButton 
				content		  = "empresas"
				destination = "/empresas"
			/>
			<MinNavButton 
				content		  = "profesionales"
				destination = "/profesionales"
			/>
		</StyledAsideMenu>
	)
}

export default AsideMenu