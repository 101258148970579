/*----- Core -----*/
import React, { useState } from 'react'
import styled, { css } 		 from 'styled-components'
import { NavLink }  			 from 'react-router-dom'

/*----- Commons -----*/
import Device			from '../../commons/Devices'
import Montserrat	from '../../commons/fonts/Montserrat'
import Color			from '../../commons/Color'

/*----- Assets -----*/
import Logo 		 from '../../assets/logos/logo.svg'
import MenuIcon  from '../../assets/icons/menu.svg'
import CloseIcon from '../../assets/icons/close-icon-white.svg'

/*----- Components -----*/
import FbIcon from '../../assets/icons/social_network/facebook.svg'
import IgIcon from '../../assets/icons/social_network/instagram.svg'

const StyledFloatedMenu = styled.aside`
	position: absolute;
	top: 0;
	right: -500px;
	height: 100vh;
	max-width: 320px;
	width: 100%;
	background-color: ${Color.main};
	display: flex;
	flex-direction: column;
	padding: 32px;
	transition: all 0.5s;
	z-index: 11;
	.floated-menu__button-container {
		margin-top: 64px;
		display: flex;
    flex-direction: column;
	}
	.floated-menu__button {
		${ Montserrat(18,500, 'white') };
		text-transform: capitalize;
		margin: 12px 0;
		
	}
	.floated-menu__close {
		position: absolute;
    top: 32px;
    right: 32px;
		border: none;
		height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
		cursor: pointer;
		&:focus {
			border: none;
    	outline: none;
		}
		img {
			width: 16px;
		}
	}
	.floated-menu__title {
		${ Montserrat(12, 600, 'white') };
		border-bottom: 1px solid white;
	}
	.social {
		img {
			width: 20px;
		}
	}
	${props => props.menuIsOpen && css`
		box-shadow: 0px 0px 128px 100px rgba(0,0,0,0.1);
		right: 0;
		@media ${Device.xl} {
			right: -32px;
			top: -32px;
		}
  `}
	@media ${Device.xl} {
		top: -32px;
	}
`

const FloatedMenu = ({ menuIsOpen, setMenuIsOpen }) => (
	<StyledFloatedMenu className="floated-menu" menuIsOpen={menuIsOpen}>
		<button className="floated-menu__close" onClick={() => setMenuIsOpen(false)}>
			<img src={ CloseIcon } alt="Menu icon"/>
		</button>
		<div className="floated-menu__button-container" >
			<NavLink to="/promo" className="floated-menu__button" onClick={() => setMenuIsOpen(false)}>
				Promo Moto
			</NavLink>
			<NavLink to="/" className="floated-menu__button" onClick={() => setMenuIsOpen(false)}>
				Home
			</NavLink>
			<NavLink to="/contacto" className="floated-menu__button" onClick={() => setMenuIsOpen(false)}>
				Contacto
			</NavLink>
			<NavLink to="/nosotros" className="floated-menu__button" onClick={() => setMenuIsOpen(false)}>
				Nosotros
			</NavLink>
			<div className="floated-menu__title p-b-1 m-t-3">Seguros</div>
			<div className="m-t-3">
				<NavLink to="/individuales" className="floated-menu__button" onClick={() => setMenuIsOpen(false)}>
					Individuales
				</NavLink>
			</div>
			<NavLink to="/empresas" className="floated-menu__button" onClick={() => setMenuIsOpen(false)}>
				Empresas
			</NavLink>
			<NavLink to="/profesionales" className="floated-menu__button" onClick={() => setMenuIsOpen(false)}>
				Profesionales
			</NavLink>
			<div className="floated-menu__title p-b-1 m-t-3">Encontranos</div>

			<div className="socail m-t-3">
			<span>
				<a className="social" href="https://www.facebook.com/segurosehrlich" target="blank">
					<img src={FbIcon} alt="facebook icon"/>
				</a>
			</span>
			<span className="m-l-2">
				<a className="social" href="https://www.instagram.com/segurosehrlich/" target="blank">
					<img src={IgIcon} alt="facebook icon"/>
				</a>
			</span>

			</div>
		</div>
	</StyledFloatedMenu>
)


/*----- Styles-----*/
const StyledHeader = styled.header`
	top: 0;
	left: 0;
	max-width: 100%;
	border-radius: 0;
	width: 100%;
	display: grid;
	grid-template-columns: 130px auto 40px;
	padding: 0px 24px;
	box-shadow: 0px 30px 96px 0px rgba(64, 92, 255, 30%);
	align-items: center;
	position: fixed;
	z-index: 10;
	background-color: white;

	.header__logo {
		img {	width: 100px }
	}

	.header__content {
		margin: 0;
		margin-left: 32px;
		opacity: 0;
		${ Montserrat(14, 500, Color.monochrome.black) };
	}

	.header__menu {
		border: none;
    background: none;
    cursor: pointer;
		padding: 8px;
		&:focus {
			border: none;
    	outline: none;
		}
		img { width: 24px }
	}

	.header__logo, .header__menu {
		padding: 16px 0;
	}

	@media ${Device.lg} {
		top: 32px;
    right: 32px;
		left: inherit;
    max-width: 100%;
    border-radius: 0;
		max-width: 600px;
		border-radius: 10px;
		.header__content {
			opacity: 1;
		}
		.header__logo {
			border-right: 1px solid ${ Color.monochrome.lightgrey};
		}
	}

`

const Header = () => {
	const [ menuIsOpen, setMenuIsOpen ] = useState(false)
	return (
		<StyledHeader className="header">
			<FloatedMenu menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
			<NavLink to="/" className="header__logo" onClick={() => setMenuIsOpen(false)}>
				<img src={ Logo } alt="logo" />
			</NavLink>
			<p className="header__content">Productores Asesores de Seguros</p>
			<button className="header__menu" onClick={ () => setMenuIsOpen(true) } ><img src={ MenuIcon } alt="menu icon" /></button>
		</StyledHeader>
	);
}
export default Header