function cardStyles() {
	return (
		`
			.product-card__mobile-container {
				align-items: stretch;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				overflow-x: auto;
				overflow-y: hidden;

				.product-card {
					margin-right: 32px;
					&:last-child {
						margin-right: 32px;
					}
				}
			}
			.product-card__desktop-container {
				display: flex;
				flex-direction: row;
				justify-content: center;
				.product-card, .product-vertical-card {
					margin-right: 32px;
				}
			}
			.desktop-container {
				display: grid;
				max-width: 1000px;
				width: 100%;
			}
			.desktop-container__col-4 {
				grid-template-columns: 25% 25% 25% 25%;
				padding-right: 48px;
				grid-column-gap: 16px;
			}
			.desktop-container__col-3 {
				grid-template-columns: 33.3% 33.3% 33.3%;
				padding-right: 16px;
				grid-column-gap: 16px;
				.product-card, .product-vertical-card {
					max-width: 100%;
					height: 380px;
				}
			}
			.desktop-container__col-2 {
				grid-template-columns: 50% 50%;
				grid-column-gap: 32px;
				padding-right: 48px;
			}
			.desktop-container__col-1 {
				grid-template-columns: 100%;
			}

			.product-card__mobile-container {
				align-items: stretch;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				overflow-x: auto;
				overflow-y: hidden;

				.product-card {
					margin-right: 32px;
					&:last-child {
						margin-right: 32px;
					}
				}
			}
		`
	)
}
export default cardStyles