/*----- Imports -----*/
import React   from 'react'
import styled  from 'styled-components'

/*----- Assets -----*/
// import InstagramIcon from  '../../../assets/icons/social_network/instagram.svg'
// import FacebookIcon  from  '../../../assets/icons/social_network/facebook.svg'
import SNNlogo       from  '../../assets/logos/ssn_logo.svg'

/*----- Ant design -----*/
import { Row } from 'antd'

/*----- Components -----*/
import AsideMenu        from '../../components/navigation/AsideMenu'
import Device           from '../../commons/Devices'
import Paragraph        from '../../components/text/Paragraph'
import IntivaFooter 		from './IntivaFooter'

/*----- Styles -----*/
const StyledFooter = styled.footer`
	padding: 32px 0;
	.footer__legal > p { font-size: 12px }
	.ssn {
		display: flex;
		img {
			width: 90px;
			margin: -4px 16px 16px 16px;
		}
		p {
			margin: 0;
			line-height: 12px;
			font-size: 10px;
		}
	}
	/*** Media queries ***/
	@media ${ Device.lg } {
	}
`

/*----- Component -----*/
const Footer = () => {
	return(
		<StyledFooter className="footer">

			<Row className="ssn" type="flex" justify="center">
				<img src={ SNNlogo } className="icon m-b-3"  alt="snn logo"/>
				<div>
					<Paragraph content="Nº Inscripción en SSN 41878" />
					<Paragraph content="Atención al asegurado 0800-666-8400" />
					<Paragraph content="Organismo de control www.argentina.gov.ar/ssn" />
				</div>
			</Row>

			<Row>
				<AsideMenu />
			</Row>

			<Row type="flex" justify="center" className="footer__legal m-2">
				<Paragraph content={ `© Ehrlich Seguros ${new Date().getFullYear()} - Todos los derechos reservados`} />
			</Row>

			<Row>
				<IntivaFooter />
			</Row>

		</StyledFooter>
	)
}

export default Footer