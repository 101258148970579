/*----- Core -----*/
import React      		 from 'react'
import styled, { css } from 'styled-components'
import PropTypes 			 from 'prop-types'

/*----- Commons -----*/
import Montserrat from '../../commons/fonts/Montserrat'
import Color 			from '../../commons/Color'
import Device     from '../../commons/Devices'

/*----- Styles -----*/
const StyledParagraph = styled.p`
	${ Montserrat(14, 400, Color.monochrome.black ) };
	margin: 0;
	max-width: 600px;


	@media ${ Device.xl } {
		max-width: 800px;
		font-size: 16px;
	}

	${props => props.highlighted && css`
			${ Montserrat(18, 600, Color.monochrome.black ) };
			@media ${ Device.lg } {
				font-size: 24px;
			}
	`}
	${props => props.invert && css`
			color: white;
	`}
	${props => props.bold && css`
			font-weight: 600;
	`}


`

/*----- Component -----*/
const Paragraph = ({ content, highlighted, invert, bold }) => {
	return (
		<StyledParagraph className="custom-paragraph" bold={bold} invert={invert} highlighted={highlighted}>
			{
				content.split("\\n").map(function(line, n) {
					return (n === 0) ? [line] : [<br />, line]
				})
			}
		</StyledParagraph>
	)
}
export default Paragraph

/*----- PropTypes -----*/
Paragraph.propTypes = {
  content: PropTypes.string.isRequired
}
