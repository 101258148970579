
/*----- Core -----*/
import React, { Suspense, lazy } 		from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

/*----- Components -----*/
import Spinner from '../../components/commons/Spinner'

/*----- Nav Components -----*/
import Header	from "../../components/navigation/Header"
import Footer from "../../components/navigation/Footer"

/*----- Modules -----*/
const Home 								 = lazy(() => import("../home/index"))
const IndividualInsurance  = lazy(() => import("../insurance_individual/index"))
const BusinessInsurance    = lazy(() => import("../insurance_business/index"))
const Professionalnsurance = lazy(() => import("../insurance_professional/index"))
const ContactPage					 = lazy(() => import("../contact/index"))
const PromoPage						 = lazy(() => import("../promo/index"))
const UsPage							 = lazy(() => import("../us/index"))

const SpinnerScreen = () => (
	<div className="spinner-container">
		<Spinner />
	</div>
)

const ViewsContainer = () => {

	return (
		<>
			<Suspense fallback={<SpinnerScreen />}>

				
				<Router>
					<Header />
					<Switch>
						<Route exact path="/" 				 						   component = { Home }/>
						<Route exact path="/individuales"    component = { IndividualInsurance }/>
						<Route exact path="/empresas"	     component = { BusinessInsurance }/>
						<Route exact path="/profesionales"	 component = { Professionalnsurance }/>
						<Route exact path="/contacto"	   						 component = { ContactPage }/>
						<Route exact path="/promo"	   							 component = { PromoPage }/>
						<Route exact path="/nosotros"	   									 component = { UsPage }/>
					</Switch>
					<Footer />
				</Router>
				


			</Suspense>
		</>
	)
}
export default ViewsContainer