/*----- Index -----*/
import { createGlobalStyle } from "styled-components"

/*----- Children -----*/
import cardStyles		 		from './components/Cards'
import paddingStyles 		from './spacing/Padding'
import marginStyles  		from './spacing/Margin'
import insuranceStyles  from './insurance/InsuranceList'

/*----- Commons -----*/
import Montserrat from '../commons/fonts/Montserrat'
import Color		  from "../commons/Color"

export default createGlobalStyle`

	.mobile-slider-product {
		margin-top: 60px;
	}
	.mobile-slider-product__slogan {
		background-color: ${ Color.main};
		width: 100%;
	}


	${ cardStyles() }
	${ paddingStyles() }
	${ marginStyles() }
	${ insuranceStyles() }

  body {
    margin: 0;
		padding: 0;
  }

	.flex {
		display: flex;
	}
	.flex-column {
		flex-direction: column;
	}
	.flex-row {
		flex-direction: row;
	}
	.j-c-s {
		justify-content: start;
	}
	.j-c-c {
		justify-content: center;
	}
	.j-c-e {
		justify-content: end;
	}
	.a-i-s {
		align-items: start;
	}
	.a-i-c {
		align-items: center;
	}
	.a-i-e {
		align-items: end;
	}

	.spinner-container {
		height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
		flex-direction: column;
	}

	/*** Ant Design overwrite ***/
	.ant-form-item-label > label {
		${ Montserrat(12, 600, Color.monochrome.black) };
	}
	.ant-input {
		${ Montserrat(16, 400, Color.monochrome.black) };
		border-color: 1px solid ${ Color.main };
	}
	.ant-input, .ant-input-lg, .ant-input-number-input {
		border-color: 1px solid ${ Color.main };
		border-bottom: 2px solid ${ Color.main };
	}

`