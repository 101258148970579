/*----- Core -----*/
import React from 'react'

/*----- Components -----*/
import ViewsContainer from '../src/modules/commons/ViewsContainer'

/*----- Styles -----*/
import 'antd/dist/antd.css'
import GlobalStyles from './styles/index'

/*----- Export -----*/
export default function App() {
	return (
			<>
				<ViewsContainer />
				<GlobalStyles />
			</>
	)
}