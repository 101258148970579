function Padding() {
	return (
		`
			.m-1 {
				margin: 8px;
			}
			.m-2 {
				margin: 16px;
			}
			.m-3 {
				margin: 32px;
			}
			.m-4 {
				margin: 64px;
			}
			.m-x-1 {
				margin-right: 8px;
				margin-left: 8px;
			}
			.m-x-2 {
				margin-right: 16px;
				margin-left: 16px;
			}
			.m-x-3 {
				margin-right: 32px;
				margin-left: 32px;
			}
			.m-x-4 {
				margin-right: 64px;
				margin-left: 64px;
			}
			.m-y-1 {
				margin-top: 8px;
				margin-bottom: 8px;
			}
			.m-y-2 {
				margin-top: 16px;
				margin-bottom: 16px;
			}
			.m-y-3 {
				margin-top: 32px;
				margin-bottom: 32px;
			}
			.m-y-4 {
				margin-top: 64px;
				margin-bottom: 64px;
			}
			.m-l-1 {
				margin-left: 8px;
			}
			.m-l-2 {
				margin-left: 16px;
			}
			.m-l-3 {
				margin-left: 32px;
			}
			.m-l-4 {
				margin-left: 53px;
			}
			.m-r-1 {
				margin-right: 8px;
			}
			.m-r-2 {
				margin-right: 16px;
			}
			.m-r-3 {
				margin-right: 32px;
			}
			.m-r-4 {
				margin-right: 64px;
			}
			.m-t-1 {
				margin-top: 8px;
			}
			.m-t-2 {
				margin-top: 16px;
			}
			.m-t-3 {
				margin-top: 32px;
			}
			.m-t-4 {
				margin-top: 64px;
			}
			.m-b-1 {
				margin-bottom: 8px;
			}
			.m-b-2 {
				margin-bottom: 16px;
			}
			.m-b-3 {
				margin-bottom: 32px;
			}
			.m-b-4 {
				margin-bottom: 64px;
			}
		`
	)
}
export default Padding