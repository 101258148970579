const colors = {
	main: '#405CFF',
	secondary: '#95FFCD',
	darkBlue: '#2b3570',
	monochrome: {
		softgrey: '#F8F8F8',
		midgrey: '#ebebeb',
		lightgrey: '#C6C6C6',
		grey: '#727272',
		black: '#212123'
	}
}
  
export default colors